<template>
  <div>
    <title>SIM INVENTORY ~ REPORTING WIP BALANCE</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Reporting WIP Balance
        <br />
        <h4>The following is a list of reporting WIP balance</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Reporting</a>
        </li>
        <li class="active">Reporting WIP Balance</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>

        <div class="row">
          <div class="col-md-12">
            <div class="box box-primary">
              <div class="box-header">
                <i class="fa fa-list"></i>
                <h3 class="box-title">Reporting WIP Balance</h3>
              </div>
              <div class="box-body">
                <div class="table-responsive">
                  <table id="mydata" class="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>Batch Code</th>
                        <th>Process Name</th>
                        <th>Process Results</th>
                        <th>Process Date</th>
                        <th>Process Out Results</th>
                        <th>Stock</th>
                        <th>Part No.</th>
                        <th>Part name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="classdata in list_paged"
                        :key="classdata.id"
                      >
                        <!-- <td>{{ classdata.id }}</td> -->
                        <td>{{ classdata.nomor_produksi }}</td>
                        <td>{{ classdata.nama_proses }}</td>
                        <td>{{ classdata.hasil_proses }}</td>
                        <td>{{ classdata.tanggal_proses }}</td>
                        <td>{{ classdata.hasil_proses_out }}</td>
                        <td>{{ classdata.stok }}</td>
                        <td>{{ classdata.part_no }}</td>
                        <td>{{ classdata.part_name }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import swal from "sweetalert";
import axios from "axios";
export default {
  data() {
    return {
      list_paged: [],
      mulai: "",
      selesai: "",
      jenisbc: "",
      isSearch: false
    };
  },
  created(){
      this.fetchdata()
  },
  methods: {
    fetchdata() {
      this.loading = true;
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "laporan_bc/get_v_bc_wip"
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.list_paged = resp.data.data;
          this.loading = false;
        })
        .catch((err) => {
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          })
        });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>

